<template>
  <div>
    <b-navbar class="nav-on-top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="#">
        <img src="../assets/logo.png" alt="logo" style="max-height: 20px" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse" class="menu-bar">
        <b-navbar-nav>
          <b-nav-item href="#/dashboard" class="dashboard">
            Dashboard
          </b-nav-item>
          <!-- start aici inseram restul de elemente din meniu -->
          <b-nav-item href="#/app/tables" class="app"> App </b-nav-item>
          <b-nav-item href="#/mese" class="mese"> Tables </b-nav-item>
          <b-nav-item href="#/orders" class="orders"> Orders </b-nav-item>
          <b-nav-item href="#/category" class="category"> Category </b-nav-item>
          <b-nav-item href="#/products" class="products"> Products </b-nav-item>
          <b-nav-item
            href="#/methods_of_preparation"
            class="methods_of_preparation"
          >
            Methods Of Preparation
          </b-nav-item>
          <b-nav-item href="#/extra" class="extra"> Extra </b-nav-item>
          <b-nav-item href="#/settings" class="settings"> Settings </b-nav-item>

          <!-- end aici inseram restul de elemente din meniu -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown text="Users and rules" right>
            <b-dropdown-item href="#/utilizatori">Users</b-dropdown-item>
            <b-dropdown-item href="#/grupuri-utilizatori"
              >Users Groups</b-dropdown-item
            >
            <b-dropdown-item href="#/categorii-drepturi"
              >Rules category</b-dropdown-item
            >
            <b-dropdown-item href="#/drepturi">Rules</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item @click="change_password()"> Change passowrd</b-nav-item>

          <b-nav-item @click="onLogout()" right>
            Logout ({{ email }})</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <change-password-dialog ref="changePassDlg"></change-password-dialog>
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";
import ChangePassword_dialog from "@/pages/ChangePassword_dialog";

export default {
  name: "Login",
  data() {
    return {
      user_type: "",
      email: JSON.parse(
        window.localStorage.getItem("5dollarcoffer-user_email")
      ),
    };
  },
  components: {
    "change-password-dialog": ChangePassword_dialog,
  },
  methods: {
    post: async function (url, args = {}) {
      this.loadingVisible = true;
      var response = await this.$http.post(url, args);
      this.loadingVisible = false;
      if (settings.verify_response(response)) {
        return response.body;
      } else {
        this.$router.push("/");
      }
    },

    change_password: function () {
      this.$refs["changePassDlg"].show_me();
    },

    handleSelect: function (item) {
      console.log(item);
    },

    async onLogout() {
      settings.logout();
      this.$router.push("/");
    },
  },
  mounted() {
    this.user_type = settings.get_user_type();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.nav-on-top {
  z-index: 1000;
}

.navbar {
  margin-top: 20px;
}

.bg-dark {
  background-color: #152e33 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #44b0cc;
  text-transform: uppercase;
  font-size: 12px;
}

.navbar-dark .active .nav-link {
  background-color: #0d7aa3;
  color: white;
}
</style>
