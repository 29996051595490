<template>
  <el-dialog
    title="Change passowrd"
    :visible.sync="showPopup"
    class="my-dialog-class"
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
    >
      <el-row :gutter="15">
        <el-col :span="8">
          <el-form-item label="Old password">
            <el-input
              show-password
              class="full-width"
              v-model="selectedObject.OldPassword"
              :autofocus="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="New password">
            <el-input
              show-password
              class="full-width"
              v-model="selectedObject.NewPassword"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Re-enter new password">
            <el-input
              show-password
              class="full-width"
              v-model="selectedObject.NewPassword2"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Close </el-button>
      <el-button type="primary" @click="save"> Save </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";

export default {
  name: "ChangePassword_dialog",
  data() {
    return {
      showPopup: false,
      selectedObject: {
        OldPassword: "",
        NewPassword: "",
        NewPassword2: "",
      },
      Info: {},
      rules: {
        OldPassword: [{ required: true, message: "This field is required" }],
        NewPassword: [{ required: true, message: "This field is required" }],
        NewPassword2: [{ required: true, message: "This field is required" }],
      },
    };
  },
  methods: {
    show_me: async function () {
      this.showPopup = true;
      this.selectedObject.OldPassword = "";
      this.selectedObject.NewPassword = "";
      this.selectedObject.NewPassword2 = "";
    },
    post: async function (url, args = {}) {
      this.loadingVisible = true;
      var response = await this.$http.post(url, args);
      this.loadingVisible = false;
      if (settings.verify_response(response)) {
        return response.body;
      } else {
        this.$router.push("/");
      }
    },
    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          var response = await this.post("utilizatori/change_password", {
            info: this.selectedObject,
          });
          if (response.HasError == false) {
            this.$message({
              message: "Parola schimbaa cu success!",
              type: "success",
            });
            this.showPopup = false;
          } else {
            this.$message.error(response.Error);
          }
        }
      });
    },
  },
  mounted: function () {},
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>