<template>
  <el-dialog title="Products" :visible.sync="showPopup" class="my-dialog-class">
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item label="Name" prop="Name">
            <el-input class="full-width" v-model="selectedObject.Name" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Price" prop="Price">
            <el-input-number
              class="full-width"
              v-model="selectedObject.Price"
              :precision="2"
              :max="999999"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Order Number" prop="OrderNumber">
            <el-input-number
              class="full-width"
              v-model="selectedObject.OrderNumber"
              :precision="0"
              :max="999999"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Category" prop="IdCategory">
            <el-select v-model="selectedObject.IdCategory" class="full-width">
              <el-option
                v-for="item in Info.category"
                :key="'category' + item.Id"
                :label="item.Name"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Close </el-button>
      <el-button type="primary" @click="save"> Save </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Products_dialog",
  extends: BasePage,
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Name: "",
        Price: "",
        OrderNumber: "",
        IdCategory: "",
      },
      Info: {
        category: [],
      },
      rules: {
        //   Nume: [ { required: true, message: "This field is required" } ]
        Name: [{ required: true, message: "This field is required" }],
        Price: [{ required: true, message: "This field is required" }],
        OrderNumber: [{ required: true, message: "This field is required" }],
        IdCategory: [{ required: true, message: "This field is required" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("products/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
      }
    },
    async get_info() {
      var response = await this.post("products/get_info_for_dialog");
      this.Info.category = response.category;
    },
    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          await this.post("products/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save");
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>